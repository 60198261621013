export const ENV = process.env.NEXT_PUBLIC_ENV;

export const BASE_API_AUTH = process.env.NEXT_PUBLIC_AUTH_SERVICE;

export const BASE_API_QUESTION_BUILDER =
  process.env.NEXT_PUBLIC_QUESTION_BUILDER;

export const BASE_API_DASHBOARD = process.env.NEXT_PUBLIC_ANALYSIS_SERVICE;

export const BASE_API_PAYMENTS = process.env.NEXT_PUBLIC_PAYMENT_SERVICE;

export const BASE_API_PLAYGROUND = process.env.NEXT_PUBLIC_PLAYGROUND_SERVICE;

export const passwordValidations = [
  'Should have atleast 10 letters',
  'Should have a number',
  'Should have an uppercase character',
  'Should have a special character',
  'Should have a lowercase character',
];

export const sortByData = [
  { id: 'createdDate', text: 'Created Date' },
  { id: 'title', text: 'Title' },
  { id: 'respondent', text: 'Responses' },
  { id: 'updatedDate', text: 'Updated Date' },
];

export const orderByData = [
  { id: 'desc', text: 'Descending' },
  { id: 'asc', text: 'Ascending' },
];

export const statusData = [
  { id: 'done', text: 'Done' },
  { id: 'rejected', text: 'Rejected' },
];

export const languagesList = [
  { id: 'en', title: 'English (United States)' },
  { id: 'de', title: 'German (Germany)' },
  { id: 'zh', title: 'Chinese, Mandarin' },
  { id: 'cs', title: 'Czech (Czech Republic)' },
  { id: 'da', title: 'Danish (Denmark)' },
  { id: 'fr', title: 'French (France)' },
  { id: 'it', title: 'Italian (Italy)' },
  { id: 'ja', title: 'Japanese (Japan)' },
  { id: 'pt', title: 'Portuguese (Brazil)' },
  { id: 'es', title: 'Spanish (Spain)' },
  { id: 'tr', title: 'Turkish (Turkey)' },
  { id: 'id', title: 'bahasa (Indonesian)' },
  { id: 'ms', title: 'Malay (Malaysia)' },
  { id: 'ben',title: 'Bengali (India)' },
  { id: 'gu', title: 'Gujarat (India)' },
  { id: 'mr', title: 'Marathi (India)' },
  { id: 'hi', title: 'Hindi (India)' },
  { id: 'te', title: 'Telugu (India)' },
  { id: 'kn', title: 'Kannada (India)' },
  { id: 'ml', title: 'Malayalam (India)' },
  { id: 'mr', title: 'Marathi (India)' },
  { id: 'ta', title: 'Tamil (India)' },
];

export const TRANSCRIPT_LANGUAGES = [
  {
    id: '--',
    text: 'Multiple Languages (Select during each interview upload)',
  },
  {
    id: 'es',
    text: 'Spanish',
  },
  {
    id: 'fr',
    text: 'French',
  },
  {
    id: 'de',
    text: 'German',
  },
  {
    id: 'it',
    text: 'Italian',
  },
  {
    id: 'ru',
    text: 'Russian',
  },
  { 
    id: 'zh', 
    text: 'Chinese (Mandarin)' 
  },
  {
    id: 'th',
    text: 'Thai',
  },
  {
    id: 'ja',
    text: 'Japanese',
  },
  {
    id: 'id',
    text: 'Indonesian',
  },
  {
    id:'ar',
    text:'Arabic'
  },
  {
    id: 'tr',
    text: 'Turkish',
  },
  {
    id: 'ko',
    text: 'Korean',
  },
  {
    id: 'nl',
    text: 'Dutch',
  },
  {
    id: 'pl',
    text: 'Polish',
  },
  {
    id: 'tl',
    text: 'Tagalog/Filipino',
  },
  {
    id: 'hi',
    text: 'Hindi',
  },
  {
    id: 'gu',
    text: 'Gujarati',
  },
  {
    id: 'ta',
    text: 'Tamil',
  },
  {
    id: 'te',
    text: 'Telugu',
  },
  {
    id: 'kn',
    text: 'Kannada',
  },
  {
    id: 'pa',
    text: 'Punjabi',
  },
  {
    id: 'bn',
    text: 'Bengali',
  },
  { 
    id: 'en', 
    text: 'American English' 
  },
  { 
    id: 'en_uk', 
    text: 'British English' 
  },
  { 
    id: 'en_au', 
    text: 'Australian English' 
  },
  {
    id: 'el',
    text: 'Greek',
  },
  {
    id: '-',
    text: 'Automatic Detection (System will detect language)',
  }
];

export const ENGLISH_LANGUAGES = [
  { id: 'en', text: 'American English' },
  { id: 'en_uk', text: 'British English' },
  { id: 'en_au', text: 'Australian English' },
];

export const TRANSLATE_LANGUAGES = TRANSCRIPT_LANGUAGES

export const AnalysislanguagesList = [
  { id: 'en', text: 'English (United States)' },
  { id: 'manual', text: 'Manual Transcript' },
];

export const PAGINATION_LIMIT = 10;

export const roleData = [
  { id: 'Brand', text: 'Brand' },
  { id: 'Transcript_vendor', text: 'Transcript Vendor' },
  { id: 'Data_vendor_with_transcript', text: 'Data Vendor With Transcript' },
  {
    id: 'Data_vendor_with_out_transcript ',
    text: 'Data Vendor Without Transcript',
  },
  { id: 'Data_qc_vendor', text: 'Data QC vendor' },
  { id: 'Owner', text: 'Owner' },

  { id: 'Research_associate', text: 'Research Associate' },
  { id: 'Client', text: 'Client' },
  { id: 'Client_with_out_personal_info', text: 'Client Without Personal Info' },
  { id: 'Data_viewer_only_approved', text: 'Data viewer only approved' },
  { id: 'Data_viewer', text: 'Data viewer' },
];

export const operationDropDown = [
  { id: 'OP-Reject', text: 'Reject' },
  { id: 'OP-Done', text: 'Approve' },
];

export const enumerateDropDown = [
  { id: 'Done', text: 'Approve' },
  { id: 'Reject', text: 'Reject' },
];

export const operationDropDownHeader = [
  { id: 'OP-Reject', text: 'Reject' },
  { id: 'Pending', text: 'Pending' },
  { id: 'OP-Done', text: 'Approve' },
];
export const enumerateDropDownHeader = [
  { id: 'Done', text: 'Approve' },
  { id: 'Pending', text: 'Pending' },
  { id: 'Reject', text: 'Reject' },
];

export const rejectReasonDropDown = [
  { id: '0', text: 'Profile SEC mismatch - wrong SEC selected' },
  { id: '1', text: 'Profile SEC mismatch - CWE Education code' },
  { id: '2', text: 'Profile SEC mismatch - CWE Occupation code' },
  { id: '3', text: 'Profile Age mismatch' },
  { id: '4', text: 'Profile Location mismatch' },
  { id: '5', text: 'Profile Gender mismatch' },
  { id: '6', text: 'Profile - Respondent not correct' },
  { id: '7', text: 'No video captured' },
  { id: '8', text: 'Video - junk upload' },
  { id: '9', text: 'Video -  No voice' },
  { id: '10', text: 'Video - No face' },
  { id: '11', text: 'Video - irrelevant video background' },
  { id: '12', text: 'Video - same video for all question' },
  { id: '13', text: 'Audio - no audio' },
  { id: '14', text: 'Audio - audio not good / clear' },
  { id: '15', text: 'Audio - audio with irrelevant answer' },
  { id: '16', text: 'Audio - audio with multiple voice' },
  { id: '17', text: 'Audio - audio with lot of noise' },
  { id: '18', text: 'Audio - audio with lot of disturbances' },
  { id: '19', text: 'Irrelevant answer' },
  { id: '20', text: 'Bad answer' },
  { id: '21', text: 'Response is scripted' },
  { id: '22', text: 'Response is prompted' },
];

export const returnFilterTypeReportElement = (answerType: any) => {
  switch (answerType) {
    case 'matrix':
    case 'summary':
    case 'theme':
    case 'theme_multiple':
    case 'sentiment':
      return answerType;

    default:
      return 'question';
  }
};
export const SEPARATOR = '#SEP#';

// this will keep the slider function smooth
export const MOD = 10 * 100 * 100;

export const SIGN_UP_USER_TYPES = [
  { id: 'all', text: 'All' },
  { id: 'EY', text: 'EY' },
  { id: 'USA', text: 'USA' },
];
