import Cookies from 'js-cookie';

export * from './file';
export * from './string';
export * from './groupObjects';

export const getToken = () => {
  let token = '';
  const a = Cookies.get();
  if (a?.loginObj) {
    const loginObject = JSON.parse(a.loginObj);
    token = loginObject?.data?.auth_token || '';
  }
  return token;
};

export const formatMb = (num: number) => {
  if (num < 1) {
    return `${num * 1000} KBps`;
  }

  if (num > 1024) {
    return `${num / 1024} GBps`;
  }
  return `${num} MBps`;
};

export function secondsToWords(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600; // Remaining seconds after hours
  let minutes = Math.floor(seconds / 60);
  seconds %= 60; // Remaining seconds after minutes

  let result = [];
  if (hours > 0) {
    result.push(`${hours} hr${hours === 1 ? '' : 's'}`);
  }
  if (minutes > 0) {
    // round of seconds to nearest minute
    if (seconds >= 30) {
      minutes++;
    }
    result.push(`${minutes} min${minutes === 1 ? '' : 's'}`);
  }

  if (hours === 0 && minutes === 0 && seconds > 0) {
    result.push(`${seconds} sec${seconds === 1 ? '' : 's'}`);
  }

  return result.join(' ');
}