import { createSlice } from '@reduxjs/toolkit';
import {
  getThemeListLongFormat,
  clusterService,
  getVideoLevelThemeListLongFormat,
  getSearchVideo,
  searchForAllVideo,
  replaceWords,
} from '../asyncActions/longFormatActions';
import { toast } from 'react-toastify';

export type themeType = {
  id: number;
  name: string;
};

interface longFormatType {
  loading: boolean;
  longFormatThemes: themeType[];
  jobStatus: string;
  clusteringPending: boolean;
  haveExistingCluster: boolean;
  videoLevelThemesList: Array<any>;
  notesOpenPanelId: string;
  notesStarId: string;
  playgroundResponse: Array<any>;
  playgroundUsersList: Array<number>;
  contentAnalysisSummaryStatus: boolean;
  videoList: Array<any>;
  search: {
    isLoading: boolean;
    isSubmitting: boolean;
    searchString: string;
    searchType: string;
    result: {
      [videoId: number]: Array<{
        id: number;
        sentence: string;
        found: string;
        startIndex: number;
        endIndex: number;
        startTime: number;
        endTime: number;
      }>;
    }; 
  }
}
const initialState: longFormatType = {
  loading: false,
  longFormatThemes: [],
  jobStatus: '',
  clusteringPending: false,
  haveExistingCluster: false,
  videoLevelThemesList: [],
  notesOpenPanelId: '',
  notesStarId: '',
  playgroundResponse: [],
  playgroundUsersList: [],
  contentAnalysisSummaryStatus: false,
  videoList: [],
  search: {
    isSubmitting: false,
    isLoading: false,
    searchString: '',
    searchType: '',
    result: {},
  }
};

export const LongFormatSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setContentAnalysisSummaryStatus: (state, action) => {
      state.contentAnalysisSummaryStatus = action.payload;
    },
    setNotesStarId: (state, action) => {
      state.notesStarId = action.payload;
    },
    setPlaygroundResponse: (state, action) => {
      state.playgroundResponse = action.payload;
    },
    setPlaygroundUsers: (state, action) => {
      state.playgroundUsersList = action.payload;
    },
    openNotePanel: (state, action) => {
      state.notesOpenPanelId = action.payload;
    },
    updateJobStatus: (state, action) => {
      state.jobStatus = action.payload;
    },
    updateClusteringPending: (state, action) => {
      state.clusteringPending = action.payload;
    },
    updateHaveExistingCluster: (state, action) => {
      state.haveExistingCluster = action.payload;
    },
    updateVideoList: (state, action) => {
      state.videoList = action.payload;
    },
    updateSearchString: (state, action) => {
      state.search.searchString = action.payload.searchString;
      state.search.searchType = action.payload.searchType;

      if (action.payload.result) {
        state.search.result = action.payload.result;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThemeListLongFormat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getThemeListLongFormat.fulfilled, (state, action) => {
        state.longFormatThemes = action.payload.data.data;
        state.jobStatus = action.payload.data.jobStatus;
        state.loading = false;
      })
      .addCase(getThemeListLongFormat.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(getVideoLevelThemeListLongFormat.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoLevelThemeListLongFormat.fulfilled, (state, action) => {
        state.videoLevelThemesList = action.payload.data.value;
        state.loading = false;
      })
      .addCase(getVideoLevelThemeListLongFormat.rejected, (state, payload) => {
        state.loading = false;
      })
      .addCase(clusterService.fulfilled, (state) => {
        state.jobStatus = 'pending';
        toast.success('Theme Generation Successfull');
      })
      .addCase(clusterService.rejected, (state) => {
        toast.error('Themes Failed to Generate');
      })
      .addCase(getSearchVideo.fulfilled, (state, action) => {
        const {videoId, results} = action.payload.data;
        state.search.result[videoId] = results;
      })
      .addCase(searchForAllVideo.pending, (state) => {
        state.search.isLoading = true;
      })
      .addCase(searchForAllVideo.rejected, (state) => {
        state.search.isLoading = false;
      })
      .addCase(searchForAllVideo.fulfilled, (state, action) => {
        state.search.isLoading = false;
      })
      .addCase(replaceWords.pending, (state) => {
        state.search.isSubmitting = true;
      })
      .addCase(replaceWords.rejected, (state) => {
        state.search.isSubmitting = false;
        toast.error('Failed to update');
      })
      .addCase(replaceWords.fulfilled, (state, action) => {
        state.search.isSubmitting = false;
        toast.success('Updated Successfully');

        const removeSentenceIdList = action.payload;
        for (const videoId in state.search.result) {
          state.search.result[videoId] = state.search.result[videoId].filter((sentence) => {
            return !removeSentenceIdList.includes(sentence.id);
          });
        }
      });
  },
});

export const { actions: LongFormatActions } = LongFormatSlice;
export default LongFormatSlice.reducer;
